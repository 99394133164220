import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const BenefitItem = ({ classNames, description, icon, title, titleIsLarge }) => (
  <div className={`benefit-item${classNames ? ` ${classNames}` : ''}`}>
    <div className="benefit-item__icon-wrapper">
      {icon && (
        icon
      )}
    </div>
    <div className="benefit-item__text">
      {!!title && (
      <div className={`benefit-item__title${titleIsLarge ? ' benefit-item__title--large' : ''}`}>
        <FormattedMessage id={title} />
      </div>
      )}
      {!!description && (
      <div className="benefit-item__description">
        <FormattedMessage id={description} />
      </div>
      )}
    </div>
  </div>
);


BenefitItem.propTypes = {
  classNames: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.func,
  title: PropTypes.string,
  titleIsLarge: PropTypes.bool
};

BenefitItem.defaultProps = {
  classNames: null,
  description: null,
  icon: null,
  title: null,
  titleIsLarge: false
};

export default BenefitItem;
